

































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, State, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import EntityType from '@/models/EntityType';
import FieldDefinitionLoader from '@/services/FieldDefinitionLoader';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });

export default Vue.extend({
  name: 'ZoneLogs',
  components: { SmartTable },
  data() {
    return {
      copiedElement: {},
      LoadState,
      smartTableState: new State(new Sorting('ZoneLogId', 'descending')),
      smartTableConfig: new Config(
        (row) => row.ZoneLogId,
        [
          new Column({
            title: this.$t('views.reporting.zoneLogs.tableHeaders.id').toString(),
            fieldName: 'ZoneLogId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.reporting.zoneLogs.tableHeaders.zoneNumber').toString(),
            fieldName: 'ZoneNumber',
            fieldType: 'numeric',
            editable: () => false
          }),
          new Column({
            title: this.$t('views.reporting.zoneLogs.tableHeaders.trackingNumber').toString(),
            fieldName: 'TrackingNumber',
            fieldType: 'numeric',
            editable: () => false
          }),
          new Column({
            title: this.$t('views.reporting.zoneLogs.tableHeaders.cycleNumber').toString(),
            fieldName: 'CycleNumber',
            fieldType: 'numeric',
            editable: () => false
          })
        ],
        new Sorting('ZoneLogId', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'ZoneLogs'),
      selected: undefined as unknown | undefined,
      windowPartLoadState: LoadState.NotStarted
    };
  },
  async created() {
    await FieldDefinitionLoader.loadDynamicColumnDefinitionsToSmartTableConfig(
      EntityType.ZoneLog,
      this.smartTableConfig,
      false
    );
  }
});
